import { ContainerLg } from '/components/buildingBlocks/Container'
import { ListItem } from '/components/buildingBlocks/ListItem'
import { config, useInView, useSprings } from 'react-spring'
import { routeMap } from '/routeMap'

import styles from './CompanyVision.css'

export function CompanyVision({ layoutClassName }) {
  const [isInViewRef, inView] = useInView({ once: true, rootMargin: '-25% 0%' })
  const [springs] = useSprings(4, i => ({
    config: config.slow,
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 10,
    delay: i * 100
  }), [inView])

  return (
    <ContainerLg {...{ layoutClassName }}>
      <ul ref={isInViewRef} className={styles.component}>
        <a href={routeMap.find()} className={styles.link}>
          <ListItem
            count='01'
            heading='We find talent'
            text={`The best talent, the coolest jobs and projects, all across Europe.`}
            animation={springs[0]}
          />
        </a>

        <a href={routeMap.engage()} className={styles.link}>
          <ListItem
            count='02'
            heading='We engage talent'
            text={`Establishing meaningful connections, for any type of employment contract.`}
            animation={springs[1]}
          />
        </a>

        <ListItem
          count='03'
          heading='We make talent grow'
          text={`Investing time, knowledge and energy in both our professionals and our clients.`}
          animation={springs[2]}
        />


        <ListItem
          count='04'
          heading='We help talent flow'
          text={`Because today's perfect match might not be tomorrow's.`}
          animation={springs[3]}
        />
      </ul>
    </ContainerLg>
  )
}
